import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import ImageGallery from "../../components/image-gallery"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Title from "../../components/title"

export const query =  graphql`
  query TEMPLATE_WP_CONTAINER_PAGE_QUERY(
    $id: String!
  ) {
    wpPage(id: { eq: $id }) {
      ...pageData
      ...gallery
      ...pageVariables
    }
  }
`

const Page = ({ data = {} }) => {
  const { wpPage: page = {} } = data
  const { 
    pageVariables = {},
    pageMeta = {},
    smartcrawlSeo = {} 
  } = page

  const images = (page.imageGallery.gallery != null) ? page.imageGallery.gallery  : null

  const { title: metaTitle = '', metaDesc = '' } = smartcrawlSeo
  const image = pageMeta.headerImage && convertToBgImage(getImage(page.pageMeta.headerImage.localFile))
 
  return (
    <React.Fragment>
      <Seo post={data.wpPage} />
      {pageVariables.primaryColour && 
        <style dangerouslySetInnerHTML= {{__html: `
        :root {
          --bs-primary: ${pageVariables.primaryColour};
        }
        `}} />
      }
      <main>
        {image &&
          <BackgroundImage 
            {...image} 
            className="header-image"
          />
        }

        <Title title={page.title} />

        <div className="container py-5">
          <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>
        {images && 
          <ImageGallery images={images} />
        }
      </main>
    </React.Fragment>
  )
}

export default Page